let f = function(event, allowDecimal) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (allowDecimal) {
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            return event.preventDefault();
        } else {
            return true;
        }
    } else {
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return event.preventDefault();
        } else {
            return true;
        }
    }
    
}

export default f;