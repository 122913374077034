<template>
    <div class="main w-100">
        <div class="d-flex align-items-center p-4 action-bar">
            <div class="mr-3 back-icon align-items-center">
                <i class="material-icons mt-1" style="font-size:35px" @click="goBack()">navigate_before</i>
            </div>
            <h3>Donation Payment Link</h3>
        </div>
        <div class="w-100 main-body">
            <div class="content">
                <div class="d-flex align-items-center h-100 pt-4 pb-4">
                    <vs-row>
                        <vs-col vs-xs="12" vs-sm="9" vs-lg="9">
                            <vs-row>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
                                    <vs-input class="w-100" size="large" label="Name of Payment" placeholder="Enter a name for this link" v-model="linkName"></vs-input>
                                </vs-col>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
                                    <vs-input class="w-100" size="large" label="Payment For" placeholder="Payment link description" v-model="description"></vs-input>
                                </vs-col>
                            </vs-row>
                            <vs-row>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mt-5">
                                    <span class="">Minimum Amount (Optional)</span>
                                    <div class="d-flex">
                                        <select class="currency" name="currency" id="" disabled v-model="selectedCurrency">
                                            <option value="NGN">NGN</option>
                                        </select>
                                        <vs-input class="w-100" size="large" @blur="format()" @keypress="isNumber($event, true)" v-model="amount"></vs-input>
                                    </div>
                                    <span class="d-block">Leave this blank to accept any amount</span>
                                </vs-col>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mt-5">
                                    <span class="">Reference Id (Optional)</span>
                                    <vs-input class="w-100" size="large" v-model="referenceId"></vs-input>
                                </vs-col>
                            </vs-row>
                            <vs-row>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mt-5">
                                    <vs-input class="w-100" size="large" label="Donation Website" placeholder="https://mywebsite.com" description-text="A valid website to proof credibility" v-model="donationWebsite"></vs-input>
                                </vs-col>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mt-5">
                                    <vs-input class="w-100" size="large" v-model="donationPhoneNumber" @keypress="isNumber($event, false)" label="Donation Phone Number" placeholder="09083828221" description-text="Donators can contact you through this number."></vs-input>
                                </vs-col>
                            </vs-row>
                        </vs-col>
                        <vs-col vs-xs="12" vs-sm="3" vs-lg="3">
                            
                        </vs-col>
                    </vs-row>
                </div>
            </div>
            <div class="content-footer">
                <div class="d-flex flex-row-reverse align-items-center h-100">
                    <div class="mr-4">
                        <vs-button type="border" color="gray" @click="cancel">Cancel</vs-button>
                        <vs-button :disabled="buttonDisabled" @click="createLink" class="ml-4">Create Link ></vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatMoney from '../../../../utils/formatMoney';
import { mapActions } from 'vuex';
import allowNumber from '../../../../utils/allowOnlyNumber';

export default {
    name: 'DonationLink',
    data() {
        return {
            selectedCurrency: 'NGN',
            currencies: ['NGN', 'GHC'],
            showFormatted: true,
            amount: '',
            buttonDisabled: true,
            referenceId: '',
            linkName: '',
            description: '',
            donationWebsite: '',
            donationPhoneNumber: ''
        }
    },
    methods: {
        ...mapActions('paymentLink', ['createPaymentLink']),
        goBack(val) {
            this.$emit('on-back', {source: 'createLink', data: val});
        },
        format() {
            this.amount = formatMoney(this.amount, false);
        },
        isNumber(event, allowDecimal) {
            return allowNumber(event, allowDecimal);
        },
        validateAll() {
            return (this.linkName !== '' && this.description !== '' && this.donationWebsite !== '' && this.donationPhoneNumber !== '');
        },
        cancel() {
            //this.$emit('on-close');
            this.$emit('on-back', {source: 'createLink', data: null});
        },
        createLink() {
            this.$vs.loading();
            let payload = {
                data: {
                    link_name: this.linkName,
                    link_description: this.description,
                    amount: this.amount ? Number(this.amount.replace(',', '')) : 0,
                    type: 'Donation Link',
                    reference_id: this.referenceId || '',
                    creation_date: Date.now(),
                    currency: 'NGN',
                    donation_website: this.donationWebsite,
                    donation_phone_number: this.donationPhoneNumber
                },
                closeAnimation: this.$vs.loading.close,
                close: () => this.$emit('on-close')
            };
            this.createPaymentLink(payload);
        }
    },
    watch: {
        description() {
            if (this.validateAll()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        },
        linkName() {
            if (this.validateAll()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        },
        donationWebsite() {
            if (this.validateAll()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        },
        donationPhoneNumber() {
            if (this.validateAll()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .main {
        height: 100%;
    }

    .action-bar {
        height: 15%;
    }

    .main-body {
        height: 85%;
    }

    .content {
        height: 85%;
        overflow-y: scroll;
    }

    .content-footer {
        height: 15%;
        background-color: #F2F2F2;
    }

    .currency {
        border: 1px solid rgba(0, 0, 0, 0.2);
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: -1px;
    }

    .select-height {
        
    }
</style>