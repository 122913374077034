<template>
    <div class="h-100 w-100 p-4 d-flex justify-content-center align-items-center">
        <div>
            <h3 class="text-muted text-center">Select a Payment Link Type</h3>
            <vs-row class="mt-4">
                <vs-col class="bp-margin-y" vs-xs="12" vs-sm="4" vs-lg="4">
                    <div class="cursor-pointer h-100" @click="openItem('customer')">
                        <vs-card class="p-4 h-100">
                            <h4 class="text-primary">Customer Link</h4>
                            <p class="lead mt-4 text-secondary">This is a normal link for your customers where you set the fixed amount you want to be paid.</p>
                        </vs-card>
                    </div>
                </vs-col>
                <vs-col class="bp-margin-y" vs-xs="12" vs-sm="4" vs-lg="4">
                    <div class="cursor-pointer h-100" @click="openItem('subscription')">
                        <vs-card class="p-4 h-100">
                            <h4 class="text-primary">Subscription Link</h4>
                            <p class="lead mt-4 text-secondary">Create a subscription link for recurrent bills for your customers.</p>
                        </vs-card>
                    </div>
                </vs-col>
                <vs-col class="bp-margin-y" vs-xs="12" vs-sm="4" vs-lg="4">
                    <div class="cursor-pointer h-100" @click="openItem('donation')">
                        <vs-card class="p-4 h-100">
                            <h4 class="text-primary">Donation Link</h4>
                            <p class="lead mt-4 text-secondary">Create a donation link with flexible amount and send out to people. They can input any amount to credit your account.</p>
                        </vs-card>
                    </div>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreatePaymentLink',
    methods: {
        close() {
            this.$emit('on-close');
        },
        openItem(val) {
            this.$emit('on-open', {source: 'createLink', data: val});
        }
    }
}
</script>