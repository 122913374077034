<template>
    <div>
        <vs-popup :active.sync="activePrompt" :title="title" @close="onClose">
            <CreatePaymentLink v-if="currentView === 'paymentLink'" @on-close="actionClose" @on-open="actionOpen"/>
            <CustomerLink v-if="currentView === 'customer'" @on-close="actionClose" @on-open="actionOpen" @on-back="actionBack"/>
            <SubscriptionLink v-if="currentView === 'subscription'" @on-close="actionClose" @on-open="actionOpen" @on-back="actionBack"/>
            <DonationLink v-if="currentView === 'donation'" @on-close="actionClose" @on-open="actionOpen" @on-back="actionBack"/>

            <CreateCustomer v-if="currentView === 'createCustomer'" @on-close="actionClose" @on-open="actionOpen" @on-back="actionBack"/>
        </vs-popup>
    </div>
</template>

<script>
import CreatePaymentLink from '../utu/payment-links/CreatePaymentLink';
import CustomerLink from '../utu/payment-links/CustomerLink';
import SubscriptionLink from '../utu/payment-links/SubscriptionLink';
import DonationLink from '../utu/payment-links/DonationLink';

import CreateCustomer from '../utu/customers/CreateCustomer';

export default {
    name: 'AppDialog',
    components: { CreatePaymentLink, CustomerLink, SubscriptionLink, DonationLink, CreateCustomer },
    props: {
        showPrompt: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Utupay Action Required"
        },
        component: {
            type: String,
            default: ''
        }
    },
    computed: {
        activePrompt: {
            get: function() {
                return this.showPrompt;
            },
            set: function() {
                //this.promptProp = value;
            }
        },
    },
    data() {
        return {
            currentView: this.component
        }
    },
    methods: {
        onClose() {
            this.$emit('update:showPrompt', false);
            this.currentView = this.component;
        },
        actionClose() {
            this.$emit('update:showPrompt', false);
            this.currentView = this.component;
        },
        actionOpen(event) {
            switch (event.source) {
                case 'createLink':
                    this.currentView = event.data;
                    break;
            }
        },
        actionBack(event) {
            switch (event.source) {
                case 'createLink':
                    if (event.data) {
                        this.currentView = event.data;
                    } else this.currentView = this.component;
                    break;
            }
        },
    }
}
</script>