<template>
    <div class="main w-100">
        <!-- <div class="d-flex align-items-center p-4 action-bar">
            <div class="mr-3 back-icon align-items-center">
                <i class="material-icons mt-1" style="font-size:35px" @click="cancel()">navigate_before</i>
            </div>
            <h3 class="text-muted">Enter Customer Details</h3>
        </div> -->
        <div class="w-100 main-body">
            <div class="content">
                <div class="d-flex align-items-center h-100 pt-4 pb-4">
                    <vs-row>
                        <vs-col vs-xs="12" vs-sm="9" vs-lg="9">
                            <vs-row>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
                                    <vs-input class="w-100" size="large" label="Full Name" placeholder="Firstname Lastname" v-model="name"></vs-input>
                                </vs-col>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
                                    <vs-input class="w-100" size="large" label="Email" placeholder="customer@email.com" v-model="email"></vs-input>
                                </vs-col>
                            </vs-row>
                            <vs-row>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mt-5">
                                    <vs-input class="w-100" size="large" @keypress="isNumber($event, false)" label="Phone Number" placeholder="09032422817" v-model="phoneNumber"></vs-input>
                                </vs-col>
                                <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mt-5">
                                    
                                </vs-col>
                            </vs-row>
                        </vs-col>
                        <vs-col vs-xs="12" vs-sm="3" vs-lg="3">
                            
                        </vs-col>
                    </vs-row>
                </div>
            </div>
            <div class="content-footer">
                <div class="d-flex flex-row-reverse align-items-center h-100">
                    <div class="mr-4">
                        <vs-button type="border" color="gray" @click="cancel">Cancel</vs-button>
                        <vs-button :disabled="buttonDisabled" @click="createCustomer" class="ml-4">Create Customer ></vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatMoney from '../../../../utils/formatMoney';
import { mapActions } from 'vuex';
import allowNumber from '../../../../utils/allowOnlyNumber';

export default {
    name: 'CustomerLink',
    data() {
        return {
            name: '',
            email: '',
            phoneNumber: '',
            buttonDisabled: true
        }
    },
    methods: {
        ...mapActions('customer', ['createNewCustomer']),
        goBack(val) {
            this.$emit('on-back', {source: 'createLink', data: val});
        },
        format() {
            this.amount = formatMoney(this.amount, false);
        },
        validateAll() {
            return (this.name !== '' && this.email !== '' && this.phoneNumber !== '');
        },
        cancel() {
            this.$emit('on-close');
            //this.$emit('on-back', {source: 'createLink', data: null});
        },
        isNumber(event, allowDecimal) {
            return allowNumber(event, allowDecimal);
        },
        createCustomer() {
            this.$vs.loading();
            let payload = {
                data: {
                    name: this.name,
                    email: this.email,
                    phone_number: this.phoneNumber,
                    creation_mode: 'dashboard',
                    creation_date: Date.now(),
                },
                closeAnimation: this.$vs.loading.close,
                close: () => this.$emit('on-close')
            };
            this.createNewCustomer(payload);
        }
    },
    watch: {
        name() {
            if (this.validateAll()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        },
        email() {
            if (this.validateAll()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        },
        phoneNumber() {
            if (this.validateAll()) {
                this.buttonDisabled = false
            } else this.buttonDisabled = true;
        }
    }
}
</script>

<style lang="scss" scoped>
    .main {
        height: 100%;
    }

    .action-bar {
        height: 15%;
    }

    .main-body {
        height: 100%;
    }

    .content {
        height: 85%;
        overflow-y: scroll;
    }

    .content-footer {
        height: 15%;
        background-color: #F2F2F2;
    }

    .currency {
        border: 1px solid rgba(0, 0, 0, 0.2);
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: -1px;
    }
</style>